<template>
  <div class="tree-chart-box">
    <div class="tree-chart-content">
      <div id="chartDom" :style="{ width: '100%', height: '100%' }"></div>
    </div>
    <div class="no-more-data" v-if="noMoreData">
      {{ $t("personal.noMoreData") }}
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { TreeChart } from "echarts/charts";
echarts.use([TreeChart]);
import { mapState } from "vuex";
var chartDom;
var myChart;
var option;
export default {
  name: "UserRelationPage",
  data() {
    return {
      treeData: {},
      treeDataOne: {},
      treeDataTwo: {},
      noMoreData: false,
      treeConfig: { nodeWidth: 160, nodeHeight: 126, levelHeight: 160 },
      hasVerticaLength: 1,
      hasHorizontalLength: 1,
    };
  },
  watch: {
    "$store.state.user.currentNavBarTab": {
      handler: function (val, oldVal) {
        console.log("tab", this.currentNavBarTab);
        this.treeData =
          this.currentNavBarTab === 0 ? this.treeDataOne : this.treeDataTwo;
        this.hasVerticaLength = this.treeData.hasVerticaLength;
        this.hasHorizontalLength = this.treeData.hasHorizontalLength;
        this.noMoreData = !this.treeData.name;
        this.$nextTick(() => {
          this.initEcharts();
        });
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      currentNavBarTab: (state) => state.user.currentNavBarTab,
    }),
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  created() {
    this.getUserInfo();
    // if (myChart)  {
    //   window.addEventListener("resize", myChart.resize);
    // }
  },
  methods: {
    getUserInfo() {
      this.$store
        .dispatch("user/getUserInfo")
        .then((res) => {
          this.treeDataOne = this.handleData(
            res?.invitations ?? {},
            "invitation"
          );
          this.treeDataTwo = this.handleData(res?.hang_on ?? {}, "hang_on");
          this.treeData =
            this.currentNavBarTab === 0 ? this.treeDataOne : this.treeDataTwo;
          this.hasVerticaLength = this.treeData.hasVerticaLength;
          this.hasHorizontalLength = this.treeData.hasHorizontalLength;
          this.noMoreData = !this.treeData.name;
          this.$nextTick(() => {
            this.initEcharts();
          });
        })
        .catch((err) => {
          this.$store.commit("snackbar/setSnackbar", {
            show: true,
            text: err?.message || err,
          });
        });
    },
    initEcharts: function () {
      // 基于准备好的dom，初始化echarts实例
      // myChart.showLoading();
      let data = this.treeData;
      chartDom = document.getElementById("chartDom");
      myChart = echarts.init(chartDom);
      // myChart.hideLoading();
      myChart.clear();
      myChart.setOption(
        (option = {
          textStyle: {
            fontFamily: "sans-serif",
            fontSize: 16,
            fontStyle: "normal",
            fontWeight: "bold",
          },
          series: [
            {
              type: "tree",
              data: [data],
              left: "2%",
              right: "2%",
              top: "100px",
              roam: true,
              zoom: 1,
              symbol: "emptyCircle",
              orient: "vertical",
              expandAndCollapse: true,
              edgeShape: "polyline",
              itemStyle: {
                color: "#666666",
                borderWidth: 2,
                borderCap: "butt",
                borderColor: "#ffffff",
              },
              label: {
                width: 80,
                height: 80,
                position: "top",
                verticalAlign: "bottom", // 文字位置
                align: "center",
                color: "#666666",
                textBorderColor: "#ffffff",
                textBorderWidth: 1,
                border: {
                  color: "#000",
                  borderColor: "#449911",
                  borderWidth: 1,
                  borderRadius: 3,
                  padding: 5,
                },
                formatter: function (params) {
                  return (
                    params.data.name +
                    "\n" +
                    "(" +
                    params.data.invitationCode +
                    ")"
                  );
                },
              },
              leaves: {
                label: {
                  width: 80,
                  height: 80,
                  position: "top",
                },
              },
              animationDurationUpdate: 750,
            },
          ],
        }),
        true
      );
      if (option && typeof option === "object") {
        myChart.setOption(option);
        myChart.on("click", (params) => {
          if (params?.data?.invitationCode) {
            this.$copyText(params.data.invitationCode).then(
              (e) => {
                console.log("copy success", params.data.invitationCode);
                this.$store.commit("snackbar/setSnackbar", {
                  show: true,
                  text: "copy success: " + params.data.invitationCode,
                  timeout: 2000,
                });
              },
              function (e) {
                console.log("copy err:", e);
              }
            );
          }
        });
        myChart.resize();
      }
    },
    handleData(res, childName) {
      let horizontalLength = 1;
      let layerLength = [];
      let data = this.formatTreeData(res, childName);
      data.name = "You";
      if (data?.children?.length > 0) {
        this.checkHorizontalLength(data, 0, layerLength);
      }
      layerLength.unshift(data?.children?.length ?? 1);
      horizontalLength = Math.max(...layerLength);
      data.hasVerticaLength = layerLength.length;
      data.hasHorizontalLength = horizontalLength;
      return JSON.parse(JSON.stringify(data));
    },
    checkHorizontalLength(item = {}, layer = 0, length = []) {
      if (item?.children?.length > 0) {
        if (length[layer] && length[layer] > 0) {
          length[layer] += item.children.length;
        } else {
          length[layer] = item?.children?.length ?? 0;
        }
        layer++;
        item.children.forEach((child) => {
          this.checkHorizontalLength(child, layer, length);
        });
      }
    },
    formatTreeData(res, childName) {
      let data = {
        name: res?.nickname ?? "",
        avatar: res?.avatar ?? "",
        invitationCode: res?.invitationCode ?? "",
        label: {
          backgroundColor: {
            image: res?.avatar ?? "",
          },
        },
      };
      if (res[childName]?.length > 0) {
        data.children = [];
        (res[childName] || []).forEach((item) => {
          let child = {
            name: item?.nickname ?? "",
            invitationCode: item?.invitationCode ?? "",
            avatar: item?.avatar ?? "",
            label: {
              backgroundColor: {
                image: item?.avatar ?? "",
              },
            },
          };
          if (item[childName]?.length > 0) {
            child.children = this.formatTreeData(item, childName).children;
          }
          data.children.push(child);
        });
      }
      return data;
    },
  },
};
</script>

<style scoped lang="scss">
.tree-chart-box {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.tree-chart-content {
  width: 100%;
  height: calc(100% - 60px);
}

.rich-media-node {
  width: 120px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #f7c616;
  border-radius: 4px;
}

.no-more-data {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar {
  width: 48px;
  height: 48px;
  border-radius: 4px;
  margin: 0 auto;
}

.name {
  font-size: 1.4em;
  line-height: 1.4em;
}
</style>
